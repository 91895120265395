import React from 'react';
import { Link } from 'react-router-dom';

function AvailableRegions(props) {
    return (
        <>
            <div>
                <h2 className='text-[#F7810E] font-semibold text-2xl text-center md:text-4xl'>Select desired region</h2>
            </div>

            <div className='grid grid-cols-3 w-full gap-5 md:grid-cols-3  2xl:grid-cols-5'>
                <Link to={"/regions/north-central"}>                    
                    <div className='w-full  h-20 cursor-pointer rounded-xl p-2 text-[#808080] bg-[#F6F6F2] text-center md:h-32 hover:bg-[#ffe6c3] hover:!text-[#ec752f]'>
                        <div>
                            <h3 className='font-semibold text-base md:font-medium md:text-2xl'>North Central</h3>
                            <p className='font-normal hidden  text-sm md:text-base md:block'>Abuja,Benue, Kogi, Kwara, Nasarawa, Niger,Plateau</p>
                        </div>
                    </div>
                </Link>

                <Link to={"/regions/north-west"}>  
                    <div className='w-full  h-20 cursor-pointer rounded-xl p-2 text-[#808080] bg-[#F6F6F2] text-center md:h-32 hover:bg-[#ffe6c3] hover:!text-[#ec752f]'>
                        <div>
                            <h3 className='font-medium text-xl md:text-2xl'>North West</h3>
                            <p className='font-normal hidden text-sm md:text-base md:block'> Jigawa, Kaduna, Kano, Katsina, Kebbi, Sokoto, Zamfara</p>
                        </div>
                    </div>
                </Link>

                <Link to={"/regions/north-east"}>  
                    <div className='w-full  h-20 cursor-pointer rounded-xl p-2 text-[#808080] bg-[#F6F6F2] text-center md:h-32 hover:bg-[#ffe6c3] hover:!text-[#ec752f]'>
                        <div>
                            <h3 className='font-medium text-xl md:text-2xl'>North East</h3>
                            <p className='font-normal hidden text-sm md:text-base md:block'>Adamawa, Bauchi, Borno, Gombe, Taraba,Yobe</p>
                        </div>
                    </div>
                </Link>

                <Link to={"/regions/south-east"}>
                    <div className='w-full  h-20 cursor-pointer rounded-xl p-2 text-[#808080] bg-[#F6F6F2] text-center md:h-32 hover:bg-[#ffe6c3] hover:!text-[#ec752f]'>
                        <div>
                            <h3 className='font-medium text-xl md:text-2xl'>South East</h3>
                            <p className='font-normal hidden text-sm md:text-base md:block'>Abia, Anambra, Ebonyi, Enugu,Imo</p>
                        </div>
                    </div>
                </Link>

                <Link to={"/regions/south-west"}>
                    <div className='w-full  h-20 cursor-pointer rounded-xl p-2 text-[#808080] bg-[#F6F6F2] text-center md:h-32 hover:bg-[#ffe6c3] hover:!text-[#ec752f]'>
                        <div>
                            <h3 className='font-medium text-xl md:text-2xl'>South West</h3>
                            <p className='font-normal hidden text-sm md:text-base md:block'>Ekiti, Lagos, Ogun, Ondo, Osun,Oyo</p>
                        </div>
                    </div>
                </Link>

                <Link to={"/regions/south-south"}>
                    <div className='w-full  h-20 cursor-pointer rounded-xl p-2 text-[#808080] bg-[#F6F6F2] text-center md:h-32 hover:bg-[#ffe6c3] hover:!text-[#ec752f]'>
                        <div>
                            <h3 className='font-medium text-xl md:text-2xl'>South South</h3>
                            <p className='font-normal hidden text-sm md:text-base md:block'>Akwa Ibom, Bayelsa, Cross River, Delta, Edo, Rivers States.</p>
                        </div>
                    </div>
                </Link>
            </div>
        </>
    );
}

export default AvailableRegions;