import React,{useState,useEffect} from 'react';
import { useTitle } from '../Misc/Misc';
import { Link } from 'react-router-dom';
import LoaderAnimation from '../Loader/LoaderAnimation';
import {GetAPIMethodsStateless} from '../../Authorization/APICalls/GetAPIMethod';

function Properties(props) {
    useTitle("Gateway Properties - Listed Properties");
    const [propertyID,setPropertyID] = useState(null);
    const [request,setRequests] = useState({loading:false,data:null,error:false,})
    const [propertyList,setPropertyList] = useState({loading:false,data:null,error:false,});

    useEffect(()=>{
        GetAPIMethodsStateless(`properties/`,setPropertyList,true);
    },[])

    useEffect(()=>{
        
        if (request.data && !request.error ) {
            setRequests({loading:false,data:null,error:false,})
            var filtered = Object.values(Object.entries(propertyList.data).filter(([,items]) => items?.property_id !== propertyID));
            filtered = filtered.map((item)=>item[1])
            setPropertyList(propertyList=>({...propertyList,data:filtered}))            
        }
    },[request,propertyList.data,propertyID])

    const removeProperty = (propertyID) =>{
        GetAPIMethodsStateless(`remove-property/${propertyID}`,setRequests,true);
        setPropertyID(propertyID)
    }


    return (
        
        <>    
            {
                propertyList.loading || request.loading ?
                    <LoaderAnimation/>
                :
                <>
                    <div className='w-[90%] mx-auto mt-36'>
                        {
                            propertyList.data?.length === 0?
                            <div className='w-full'>
                                <svg className='w-full max-w-[100px] h-[100px] mx-auto' fill="none" stroke="gray" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819" />
                                </svg>
                                <p className='text-center font-medium text-gray-400 text-sm md:text-base'>No property has been listed yet</p>
                            </div>
                            :
                            <div className='grid gap-5 grid-cols-1 place-items-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
                                {
                                    propertyList?.data?.map((property,id)=>{
                                        return(
                                            <div key={id} className='bg-gray-50 rounded-lg p-5 w-full min-h-[20rem] cursor-pointer relative'>

                                                <svg onClick={()=>{removeProperty(property?.property_id)}} className='absolute top-0 right-0 h-7 w-7' fill="none" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                                <Link to={`/properties/${property?.property_unique_url}`}>
                                                <div>
                                                    <img src={property?.property_flier_image} alt="property"  className='w-full h-full object-contain'/>
                                                    <div className='w-full h-full p-5 grid grid-cols-2'>
                                                        <h3 className='font-medium text-base md:text-lg'>Region</h3>
                                                        <h5 className='font-normal text-gray-500 text-base whitespace-nowrap md:text-lg'>{property?.property_region}</h5>
                                                    </div>
                                                </div>
                                            </Link>
                                            </div>
                                            
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                </>
            }
        </>
    );
}

export default Properties;