import React from 'react';
import { useTitle } from '../Misc/Misc';
import AvailableRegions from './IndexPageComponents/AvailableRegions';

function IndexPage(props) {
    useTitle("Gateway Properties");
    
    return (
        <div className='w-full mx-auto'>
            <div className='w-full border-b border-b-white before:bg-[#000000] #[#677e3ee5] min-h-[35rem] h-full home-banner relative md:min-h-[50rem]' style={{backgroundImage: `url("/Images/banner1.jpg")`}}>
                <div className='flex flex-col space-y-2 w-full absolute h-full px-5 py-[13rem] md:py-[18rem]'>
                    <h2 className='text-white font-bold text-3xl uppercase text-center md:text-5xl'>Your land hunt gets easier with us</h2>
                    <div className='flex space-x-2 w-full items-center justify-center'>
                        <p className='text-white text-center font-medium text-sm md:text-base'>Finding you the most reliable location</p>
                        <svg className="w-6 h-6" fill="none" stroke="#fff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
                    </div>
                </div>
                <svg className='absolute bottom-0' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff" fillOpacity="1" d="M0,160L80,181.3C160,203,320,245,480,229.3C640,213,800,139,960,112C1120,85,1280,107,1360,117.3L1440,128L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>
            </div>

            <div className='w-[90%] flex flex-col space-y-5 my-10 mx-auto md:w-[85%] md:my-5'>
                <AvailableRegions/>
            </div>

            <div className='w-[90%] flex flex-col space-y-5 my-10 mx-auto md:w-[85%] md:my-5'>
                <div className='w-full'>
                    <h2 className='text-[#F7810E] font-semibold text-2xl text-center md:text-4xl'>Our Services</h2>
                </div>

                <div className='grid grid-col-1 w-full gap-10 md:grid-cols-2'>
                    <div className="w-full">
                        <div className="single-service">
                            <div className="content">
                                <span className="icon">
                                    <i className="fab fa-battle-net"></i>
                                </span>
                                <h3 className="title">Sell your land</h3>
                                <p className="description">We sell your land with the best market price, and very quickly as well</p>
                            </div>
                            <span className="circle-before"></span>
                        </div>
                    </div>

                    <div className="w-full">
                        <div className="single-service">
                            <div className="content">
                                <span className="icon">
                                    <i className="fab fa-battle-net"></i>
                                </span>
                                <h3 className="title">Buy your land</h3>
                                <p className="description">We offer you free consultancy to get, your first land at an economic price</p>
                            </div>
                            <span className="circle-before"></span>
                        </div>
                    </div>

                    <div className="w-full">
                        <div className="single-service">
                            <div className="content">
                                <span className="icon">
                                    <i className="fab fa-battle-net"></i>
                                </span>
                                <h3 className="title">Legal services</h3>
                                <p className="description">We sell your land with the best market price, and very quickly as well</p>
                            </div>
                            <span className="circle-before"></span>
                        </div>
                    </div>

                    <div className="w-full">
                        <div className="single-service">
                            <div className="content">
                                <span className="icon">
                                    <i className="fab fa-battle-net"></i>
                                </span>
                                <h3 className="title">Free evolution</h3>
                                <p className="description">We sell your land with the best market price, and very quickly as well</p>
                            </div>
                            <span className="circle-before"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IndexPage;