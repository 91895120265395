import React from 'react';

function PropertyDescription({data}) {
    return (
        <>
            <div className='p-5 border border-gray-300'>
                <p className='text-gray-600 font-normal text-sm md:text-base'>{data?.property_description}</p>
            </div>
        </>
    );
}

export default PropertyDescription;