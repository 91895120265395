import React,{useState,useEffect} from 'react';
import { Link,useLocation } from 'react-router-dom';



function Footer(props) {
    const { pathname } = useLocation();
    let basePath = pathname.split("/")[1];
    const [showFooter,setShowFooter] = useState(false);


    useEffect(()=>{
        const showHeaderToURLs = ["","properties","regions","contact-us"];
        let show = showHeaderToURLs.includes(basePath);
        setShowFooter(show);
    },[basePath]);


    return (
        <>
            {
                showFooter &&
           
                <div className='w-full py-10 bg-[#ffe6c3] rounded-t-3xl mt-40'>
                    <div className='w-[90%] mx-auto flex flex-col space-y-10'>

                
                        <div className='flex flex-wrap items-start flex-col-reverse justify-between md:flex-row'>
                            <div className='w-full max-w-lg'>
                                <div className='flex flex-col space-y-2 mb-5'>
                                    <h2 className='font-semibold uppercase text-xl md:text-2xl'>Get in Touch</h2>
                                    <p className='text-gray-400 font-medium text-xs md:text-base'>Want to learn more about our services?</p>
                                </div>
                                <div className='w-full h-11 relative rounded-full md:h-14'>
                                    <input type="email" name="email" id="email" className="text-sm w-full h-full text-gray-500 rounded-full appearance-none outline-none p-5 md:text-base"/>
                                    <button className="bg-[#F7810E] font-medium text-white text-sm rounded-full h-full w-full max-w-[145px] absolute right-0 top-0 md:text-base">Get in Touch</button>
                                </div>
                                
                            </div>
                            <div className='max-w-[180px] h-14 w-full -ml-5 cursor-pointer'>
                                <Link to={"/"}>
                                    <img src="/Images/logo.png" alt="" className='w-full h-full object-fill'/>
                                </Link>
                            </div>
                        </div>

                        <div className='w-full flex flex-wrap space-y-5 items-center justify-start md:justify-evenly'>

                            <div className='items-start flex space-x-5 text-center md:items-center md:space-x-0 md:flex-col'>
                                <svg className="w-6 h-6" fill="none" stroke="#F7810E" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" /></svg>
                                <h4 className='font-medium text-sm md:text-base'>+234-815-291-0458</h4>
                            </div>

                            <div className='items-start flex space-x-5 text-center md:items-center md:space-x-0 md:flex-col'>
                                <svg className="w-6 h-6" fill="none" stroke="#F7810E" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" /></svg>
                                <h4 className='font-medium text-sm md:text-base'>gatewaynetworkestate@gmail.com</h4>
                            </div>

                            <div className='items-start flex space-x-5 text-center md:items-center md:space-x-0 md:flex-col'>
                                <svg className="w-6 h-6" fill="none" stroke="#F7810E" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                                <h4 className='font-medium text-sm md:text-base'>Mon-Sat :8:00-23:00</h4>
                            </div>
                        </div>

                        <div className='w-full'>
                            <p className='font-normal text-center text-xs md:text-sm text-gray-400'>Copyright © 2022 Gateway Network</p>
                        </div>
                    </div>
                    
                </div>
            }
        </>
    );
}

export default Footer;