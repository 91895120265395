import Footer from "./Components/NavBar/Footer";
import NotFoundRoute from "./SpecialRoutes/NotFoundRoute";
import IndexHeader from "./Components/NavBar/IndexHeader";
import IndexPage from "./Components/Properties/IndexPage";
import AdminNavbar from "./Components/NavBar/AdminNavbar";
import LoginPage from "./Components/AdminDashboard/LoginPage";
import Properties from "./Components/AdminDashboard/Properties";
import ContactUsPage from "./Components/Properties/ContactUsPage";
import AddProperty from "./Components/AdminDashboard/AddProperty";
import PropertiesList from "./Components/Properties/PropertiesList";
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom';
import PropertiesDetail from "./Components/Properties/PropertiesDetail";
import ProtectedRoute from "./Authorization/ProtectedRoutes/ProtectedRoute";

function App() {
  return (
    <div className="App">
        <Router>
            <div className="relative w-full min-h-screen flex flex-col justify-between">
              <IndexHeader/>
              <AdminNavbar/>
              
              <Routes>
                  <Route exact path='/' element={<IndexPage/>} />
                  <Route exact path='/contact-us' element={<ContactUsPage/>} />
                  <Route exact path='/regions/:region' element={<PropertiesList/>} />
                  <Route exact path='/properties/:id' element={<PropertiesDetail/>} />
                  <Route exact path='/gateway-admin/login' element={<LoginPage/>} />
                  <Route element={<ProtectedRoute/>}>
                    <Route exact path='/gateway-admin/' element={<Properties/>} />
                    <Route exact path='/gateway-admin/properties' element={<Properties/>} />
                    <Route exact path='/gateway-admin/add-property' element={<AddProperty/>} />
                  </Route>
                  <Route path='*' exact={true} element={<NotFoundRoute/>} />
              </Routes>
              
              <Footer/>
            </div>
        </Router>
    </div>
  );
}


export default App;
