import './loader.css';

export default function LoaderAnimation(props){
    return(
        
        <div className='w-full h-full top-0 fixed z-10 grid place-content-center'>
            <div className='bg-[#f8fafc] z-10l h-full w-full top-0 backdrop-blur-sm absolute'></div>
            <span className="loader"></span>
        </div>
    )
}