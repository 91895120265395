import React from 'react';

function ContactUsPage(props) {
    return (
        <div className='w-[90%] mx-auto mt-36'>
            
            <div className='flex flex-col place-content-center items-center space-y-5 max-w-xl w-full mx-auto bg-gray-200 p-5 rounded-2xl'>

                <div className='flex flex-col space-y-5'>
                    <h3 className='font-medium text-base md:text-lg'>Contact us via WhatsApp:</h3>
                    <a href="https://wa.me/2348061409582?text=Hello%2C%20I%20would%20like%20to%20enquire%20about%20your%20properties" target={"_blank"} rel={"noreferrer"}>
                        <div className='w-full flex space-x-2 items-center bg-[#ffe6c3]# border border-gray-400 cursor-pointer place-content-center rounded-2xl max-w-xs mx-auto h-11 md:h-14'>
                            <div className='h-full w-10'>
                                <img src="/Images/whatsapp.svg" alt="whatsapp.svg" className='w-full h-full'/>
                            </div>
                            <h2 className='font-medium text-sm md:text-base'>Talk to an Agent</h2>
                        </div>
                    </a>
                </div>

                <div className='flex flex-col space-y-2'>
                    <h3 className='font-medium text-base md:text-lg'>Contact us via Mail</h3>
                    <h5 className='text-gray-700 font-normal text-sm md:text-base'>gatewaynetworkestate@gmail.com</h5>
                </div>
                
            </div>
        </div>
    );
}

export default ContactUsPage;