import React from 'react';
import { useTitle } from '../Misc/Misc';
import AddPropertyForm from './Forms/AddPropertyForm';

function AddProperty(props) {
    useTitle(`Gateway Properties - Add Property`);
    
    return (
        <div className='w-[90%] mx-auto my-36'>
            <AddPropertyForm/>
        </div>
    );
}

export default AddProperty;