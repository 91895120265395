import React,{useState,useEffect} from 'react';
import { Link,useLocation } from 'react-router-dom';

function AdminNavbar(props) {
    const { pathname } = useLocation();
    let basePath = pathname.split("/")[1];
    let currentPath = pathname.split("/")[2];
    const [showHeader,setShowHeader] = useState(false);
    const [showMobileNav,setMobilenNav] = useState(false);

    useEffect(()=>{
        const showHeaderToURLs = ["gateway-admin",];
        let show = showHeaderToURLs.includes(basePath);
        setShowHeader(show);
    },[basePath]);


    return (
        <>
            
            {
                showHeader &&
                <>
                    {showMobileNav && <div onClick={()=>{setMobilenNav(!showMobileNav)}} className='fixed bg-[#0000008a] z-10 h-full w-full top-0 backdrop-blur-sm'></div>}
                    <div className='w-full fixed top-0 bg-white z-50'>
                        <div className='w-[90%] mx-auto py-2 flex justify-between'>
                            <div className='max-w-[150px] h-14 w-full -ml-2 cursor-pointer'>
                                <Link to={"/"}>
                                    <img src="/Images/logo.png" alt="" className='w-full h-full object-fill'/>
                                </Link>
                            </div>
                            {
                                currentPath !== "login" &&  
                            

                                <div className='w-full max-w-xs'>
                                    <ul className='hidden justify-evenly w-full items-center h-full text-gray-500 md:flex'>
                                        <Link to={"/gateway-admin/properties"} className={`${currentPath === "properties"? "text-[#F7810E] border-b-4 border-b-[#F7810E]":""} font-medium text-sm md:text-base hover:text-[#F7810E]`}>Properties</Link>
                                        <Link to={"/gateway-admin/add-property"} className={`${currentPath === "add-property"? "text-[#F7810E] border-b-4 border-b-[#F7810E]":""} font-medium text-sm md:text-base hover:text-[#F7810E]`}>Add Property</Link>
                                    </ul>
                                    <div onClick={()=>{setMobilenNav(!showMobileNav)}} className='w-full h-full flex place-content-end items-center md:hidden'>
                                        <svg className="w-6 h-6 cursor-pointer" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z" clipRule="evenodd" /></svg>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className={`${showMobileNav?"top-0":"-top-96"} transition_0_5s w-full h-56 transition p-5 absolute max-w-xl bg-white -500`}>
                            <div onClick={()=>{setMobilenNav(!showMobileNav)}} className='w-[90%] mx-auto flex justify-end items-center'>
                                <svg className="w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg>
                            </div>
                            <ul onClick={()=>{setMobilenNav(!showMobileNav)}} className='flex flex-col mt-12 space-y-7 w-full items-center h-full text-gray-500'>
                                <Link to={"/gateway-admin/properties"} className={`${currentPath === "properties"? "text-[#F7810E] border-b-4 border-b-[#F7810E]":""} font-medium text-sm md:text-base hover:text-[#F7810E]`}>Properties</Link>
                                <Link to={"/gateway-admin/add-property"} className={`${currentPath === "add-property"? "text-[#F7810E] border-b-4 border-b-[#F7810E]":""} font-medium text-sm md:text-base hover:text-[#F7810E]`}>Add Property</Link>
                            </ul>
                        </div>
                        
                    </div>
                </>
            }
        </>
    );
}

export default AdminNavbar;