import React,{useState} from 'react';
import { useLocation } from 'react-router';
import { useTitle,toTitleCase } from '../Misc/Misc';
import LoaderAnimation from '../Loader/LoaderAnimation';
import { SERVER_URL } from '../../Authorization/APICalls/AxiosBase';
import GetAPIMethods from '../../Authorization/APICalls/GetAPIMethod';
import PropertyFeatures from './PropertyDetailsComponents/PropertyFeatures';
import PropertyDescription from './PropertyDetailsComponents/PropertyDescription';


function PropertiesDetail(props) {
    const { pathname } = useLocation();
    let propertyPath = pathname.split("/")[2];
    const [viewDetail,setViewDetail] = useState("Desc");
    const PropertyList = GetAPIMethods(`property-detail/${propertyPath}`);
    useTitle(`Gateway Properties - ${toTitleCase(propertyPath.replace("-"," ").split("-").slice(0, -1)[0])}`);

    return (
        <div className='w-full'>

            {

                PropertyList.loading?
                <LoaderAnimation/>
                :

                <>
                    <div className='w-full border-b border-b-white before:bg-[#000000] #[#677e3ee5] min-h-[35rem] h-full home-banner relative md:min-h-[50rem]' style={{backgroundImage: `url(${SERVER_URL+PropertyList.data?.property_flier_image})`}}>
                        <div className='flex flex-col space-y-2 w-full absolute h-full px-5 py-[13rem] md:py-[18rem]'>
                            <h2 className='text-white font-bold text-3xl uppercase text-center md:text-5xl'>{PropertyList.data?.property_title}</h2>
                            <div className='flex space-x-2 w-full items-center justify-center'>
                                <p className='text-white text-center font-medium text-sm md:text-base'>{PropertyList.data?.property_location}</p>
                                <svg className="w-6 h-6" fill="none" stroke="#fff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
                            </div>
                        </div>
                        <svg className='absolute bottom-0' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff" fillOpacity="1" d="M0,160L80,181.3C160,203,320,245,480,229.3C640,213,800,139,960,112C1120,85,1280,107,1360,117.3L1440,128L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>
                    </div>

                    <div className='w-[90%] mx-auto flex flex-col space-y-5'>
                        <div className='grid grid-cols-2 gap-3 max-w-xs w-full h-11 md:h-14'>
                            <div onClick={()=>{setViewDetail("Desc")}} className={`${viewDetail === "Desc"? "text-white bg-[#F7810E] border-[#F7810E] ":"text-gray-400 border-gray-300"} w-full h-full grid place-content-center  font-medium text-base md:text-lg border cursor-pointer rounded-md`}>
                                <h2>Description</h2>
                            </div>
                            <div onClick={()=>{setViewDetail("Feat")}} className={`${viewDetail === "Feat"? "text-white bg-[#F7810E] border-[#F7810E] ":"text-gray-400 border-gray-300"} w-full h-full grid place-content-center  font-medium text-base md:text-lg border  cursor-pointer rounded-md`}>
                                <h2>Features</h2>
                            </div>
                        </div>
                        {
                            viewDetail === "Desc"?
                            <PropertyDescription data={PropertyList.data}/>
                            :
                            <PropertyFeatures data={PropertyList.data}/>
                        }

                        <a href={`https://api.whatsapp.com/send?phone=2348152910458&text=Hello, I have a question about the ${PropertyList.data?.property_title}`}>
                            <div className='w-full flex space-x-2 items-center bg-[#ffe6c3]# border border-gray-400 cursor-pointer place-content-center rounded-2xl max-w-xs mx-auto h-11 md:h-14'>
                                <div className='h-full w-10'>
                                    <img src="/Images/whatsapp.svg" alt="whatsapp.svg" className='w-full h-full'/>
                                </div>
                                <h2 className='font-medium text-sm md:text-base'>Talk to an Agent</h2>
                            </div>
                        </a>
                        
                    </div>
                </>
             }
        </div>
    );
}

export default PropertiesDetail;