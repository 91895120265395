import React from 'react';
import { useTitle } from '../Misc/Misc';
import LoginForm from './Forms/LoginForm';


function LoginPage(props) {
    useTitle("Gateway Properties - Admin Login");
    return (
        <div>
            <div className="mx-auto flex flex-col justify-center p-5 items-center mt-36">
                <h3 className='text-2xl font-semibold'>Sign in as Admin</h3>
            </div>
            <LoginForm/>
        </div>
    );
}

export default LoginPage;