import {useState,useEffect} from "react";
import axiosInstance from "./AxiosBase";

export default function GetAPIMethods(QUERY_URL,useAuth=false) {
    const [request,setRequests] = useState({
        loading:false,
        data:null,
        error:false,
    })
    

    useEffect(() => {
        setRequests({loading:true,data:null,error:false})

        const Header = {headers: {Authorization: useAuth?`Bearer ${localStorage.getItem['access_token']||localStorage.access_token}`:null}};
        
        axiosInstance.get(QUERY_URL,Header).then(
            
            response => {
                setRequests({loading:false,data:response.data,error:false})
            }
            ).catch((error) =>{
            setRequests({loading:false,data:error.response.data,error:true})
            })

    },[QUERY_URL,useAuth]);
    

    return (request);
}


export function GetAPIMethodsStateless(QUERY_URL,setRequests,useAuth=false) {

    setRequests({loading:true,data:null,error:false})
    const Header = {headers: {Authorization: useAuth?`Bearer ${localStorage.getItem['access_token']||localStorage.access_token}`:null}};

    axiosInstance.get(QUERY_URL,Header).then(
        
        response => {
            setRequests({loading:false,data:response.data,error:false})
        }
        ).catch(() =>{
        setRequests({loading:false,data:null,error:true})
        })
    

    // return (setRequests);
}