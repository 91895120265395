import React,{useState,useEffect} from 'react';
import LoaderAnimation from '../../Loader/LoaderAnimation';
import FlashMessage from '../../FlashMessage/FlashMessage';
import {getAdvancedFormValues,prettyNumberInput,numberOnly} from "../../Misc/Misc";
import { PostAPIMethodStateless } from '../../../Authorization/APICalls/PostAPIMethod';



function AddPropertyForm(props) {
    const [flier,setFlier] = useState(null);
    const [request,setRequests] = useState({loading:false,data:null,error:false,})

    const onUpload = (e)=>{
        setFlier(URL.createObjectURL(e.target.files[0]))
    }

    useEffect(() => {
      if (request.error) {
        alert(JSON.stringify(request.data))
      }
    
    }, [request])
    

    const handleSubmit =(e)=>{
        e.preventDefault();

        const formData = getAdvancedFormValues();
        setFlier(null);
        PostAPIMethodStateless("add-property/",formData,setRequests);
        
    }

    const onInputNumberOnly = (e)=>{
        numberOnly(e)
        prettyNumberInput(e)
    }

    return (
        <>
        {
            request.data?.success === true && <FlashMessage message={"Property Added Successfully"} type={"success"}/>
        }
            {
                request.loading?
                <LoaderAnimation/>
                :
            
                <form onSubmit={handleSubmit} className='flex flex-col w-full max-w-lg mx-auto'>
                    <div className="input-group h-12 md:h-14">
                        <input type="text" id="property_title" name='property_title' required className="input h-full focus:ring-[#fdae5c]" autoComplete='new-password'/>
                        <label htmlFor="property_title" className="input-label">Property Name</label>
                    </div>
                    <div className="input-group h-12 md:h-14">
                        <select id="property_region" name='property_region' defaultValue={"south-west"} className="input h-full appearance-none cursor-pointer focus:ring-[#fdae5c]">
                            <option value="South East">South East</option>
                            <option value="South West">South West</option>
                            <option value="South South">South South</option>
                            <option value="North West">North West</option>
                            <option value="North East">North East</option>
                            <option value="North Central">North Central</option>
                        </select>
                        <label htmlFor="property_region" className="input-label">Property Region</label>
                    </div>

                    <div className="input-group h-12 md:h-14">
                        <input type="text" id="property_location" name='property_location' required className="input h-full focus:ring-[#fdae5c]" autoComplete='new-password'/>
                        <label htmlFor="property_location" className="input-label">Property Location</label>
                    </div>
                    <div className="input-group h-[15rem]">
                        <textarea name="property_description" id="property_description" className="input h-full resize-none focus:ring-[#fdae5c]"></textarea>
                        <label htmlFor="property_description" className="input-label">Property Description</label>
                    </div>
                    <div className='border-t-2 border-t-[#fdae5c] relative w-full'>
                        <div className='bg-[#fdae5c] rounded-full px-5 py-1 mx-auto w-full absolute -top-4 left-0 right-0 max-w-[100px]'>
                            <h3 className='font-medium text-white'>Features</h3>
                        </div>
                        <div className='flex flex-col w-full mt-10'>
                            <div className="input-group h-12 md:h-14">
                                <input onChange={onInputNumberOnly} type="text" id="plot_area" name='plot_area' required className="input h-full focus:ring-[#fdae5c]" autoComplete='new-password'/>
                                <label htmlFor="plot_area" className="input-label">Plot Area (SQFt)</label>
                            </div>
                            
                            <div className="input-group h-12 md:h-14">
                                <input onChange={onInputNumberOnly} type="text" id="listing_price" name='listing_price' required className="input h-full focus:ring-[#fdae5c]" autoComplete='new-password'/>
                                <label htmlFor="listing_price" className="input-label">Minimum Price ₦</label>
                            </div>
                            <div className="input-group h-12 md:h-14">
                                <input type="text" id="popular_landmark" name='popular_landmark' required className="input h-full focus:ring-[#fdae5c]" autoComplete='new-password'/>
                                <label htmlFor="popular_landmark" className="input-label">Popular Landmark</label>
                            </div>
                        </div>
                    </div>
                    <div className='border-t-2 border-t-[#fdae5c] relative w-full'>
                        <div className='bg-[#fdae5c] rounded-full px-5 py-1 mx-auto w-full absolute -top-4 left-0 right-0 max-w-[150px]'>
                            <h3 className='font-medium text-white'>Property Flier</h3>
                        </div>
                        <div className='flex flex-col w-full mt-10'>
                            <div className={`${flier?"hidden":"input-group"}  border border-[#fdae5c] cursor-pointer rounded-md h-12 md:h-14`}>
                                <input onChange={onUpload} type="file" id="property_flier_image" name='property_flier_image' accept="image/*" required className="input cursor-pointer h-full opacity-0 focus:ring-[#fdae5c]" autoComplete='new-password'/>
                                <label htmlFor="property_flier_image" className="input-label">Flier</label>
                            </div>
                            {
                                flier &&
                                <div className='bg-gray-50 h-40 w-full rounded-xl relative mb-10'>
                                    <img src={flier} alt={flier} className="w-full h-full object-contain"/>
                                    <svg onClick={()=>{setFlier(null)}} className="w-6 h-6 top-3 absolute right-3 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                                </div>
                            }
                        </div>
                    </div>
                    <button className='w-full rounded-xl  h-12 text-base font-semibold text-[#fdaf61] bg-[#e1e4e9] hover:bg-[#fdaf61] hover:text-white md:h-14'>Add Property</button>
                </form>
            }
        </>
    );
}

export default AddPropertyForm;