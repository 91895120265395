import React from 'react';

function PropertyFeatures({data}) {
    return (
        <div className='w-full p-5 border border-gray-300 grid grid-cols-1 md:grid-cols-3 place-content-center gap-5'>
            <div className='flex space-x-2 items-center font-normal text-gray-600 text-sm md:text-base'>
               <h3 className='font-medium'>Lot Area:</h3>
               <h4>{data?.plot_area?.toLocaleString()} SQ FT</h4>
            </div>
            <div className='flex space-x-2 items-center font-normal text-gray-600 text-sm md:text-base'>
               <h3 className='font-medium'>Popular Landmark:</h3>
               <h4>{data?.popular_landmark}</h4>
            </div>
            <div className='flex space-x-2 items-center font-normal text-gray-600 text-sm md:text-base'>
               <h3 className='font-medium'>Listing Price:</h3>
               <h4>₦{data?.listing_price?.toLocaleString()}</h4>
            </div>

            <div className='flex space-x-2 items-center font-normal text-gray-600 text-sm md:text-base'>
               <h3 className='font-medium'>Region:</h3>
               <h4>{data?.property_region}</h4>
            </div>
        </div>
    );
}

export default PropertyFeatures;