import React from 'react';
import { useTitle,toTitleCase } from '../Misc/Misc';
import { Link,useLocation } from 'react-router-dom';
import LoaderAnimation from '../Loader/LoaderAnimation';
import GetAPIMethods from '../../Authorization/APICalls/GetAPIMethod';

function PropertiesList(props) {
    const { pathname } = useLocation();
    let basePath = toTitleCase(pathname.split("/")[2]?.replace("-"," "));
    const PropertyList = GetAPIMethods(`properties/?property_region=${basePath}`);
    useTitle(`Gateway Properties - ${basePath} Region`);
    return (
        <>
            {
                PropertyList.loading?
                <LoaderAnimation/>
                :
        
                <div className='w-[90%] mx-auto mt-36'>  
                {
                    PropertyList.data?.length === 0 || PropertyList.data === null?
                    <div className='w-full'>
                        <svg className='w-full max-w-[100px] h-[100px] mx-auto' fill="none" stroke="gray" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819" />
                        </svg>
                        <p className='text-center font-medium text-gray-400 text-sm md:text-base'>There is no listed properties in this Region</p>
                    </div>
                    :
                          
                    <div className='grid gap-5 grid-cols-1 place-items-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
                        {
                            PropertyList?.data?.map((property,id)=>{
                                return(<Link key={id} to={`/properties/${property?.property_unique_url}`}>
                                    <div className='p-5 max-w-[400px]l w-full h-96l cursor-pointer'>
                                        <img src={property?.property_flier_image} alt="property"  className='w-full h-full object-contain'/>
                                    </div>
                                </Link>)
                            })
                        }
                    </div> 
                }

                    
                </div>
            }
        </>
    );
}

export default PropertiesList;